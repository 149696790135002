import * as React from "react";
import SEO from "../common/SEO";
import Header from "../common/header/Header";
import Footer from "../common/footer/Footer";
import Separator from "../components/Separator";
import { ReactComponent as ToaSvg } from "../assets/svg/turn-on-attention-clip.svg";
import { HomepageHero } from "../content/homepageHero/HomepageHero";
import SplitContentImage from "../components/SplitContentImage";
import { FeatureList } from "../components/FeatureList";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { SplitContentVideo } from "../components/SplitContentVideo";

export function Index({ data }) {
  const video = {
    id: "01",
    type: "video",
    // image: "https://img.youtube.com/vi/1VHcyeqV6Xg/hqdefault.jpg",
    image:
      "https://i.ytimg.com/vi/1VHcyeqV6Xg/hqdefault.jpg?sqp=-oaymwExCNACELwBSFryq4qpAyMIARUAAIhCGAHwAQH4AdQGgALgA4oCDAgAEAEYRyBMKHIwDw==&rs=AOn4CLC3ERDuxVnsLiSAn2GII9ul0_kG7A",
    popupLink: [
      <iframe
        src="https://www.youtube.com/embed/videoseries?list=PLda0aaf0UZp_iqVeT7vzX8ICzbNNa3mvo"
        width="1920px"
        height="1080px"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />,
    ],
  };
  return (
    <>
      <ToaSvg
        id="clipping"
        style={{ position: "absolute", width: -1, height: -1 }}
      />

      <SEO />
      <main className="page-wrapper">
        <Header transparent />
        <HomepageHero />
        <div className="rn-section-gap">
          <div className="container">
            <h1>HueBox</h1>
            <p>
              Introducing HueBox - the future of dynamic LED displays. Add
              movement and animation to your visual communication and bring your
              space to life. Grab the attention of customers and enhance their
              experience with HueBox's perfect blend of graphics and motion.
              Unleash your creativity and create a truly memorable experience
              with HueBox's versatility.
            </p>
          </div>
        </div>
        <Separator />
        <div className="rn-section-gap">
          <div className="container">
            <SplitContentVideo video={video}>
              <h3
                style={{
                  fontWeight: 400,
                  marginBottom: 35,
                }}
              >
                Dynamic{" "}
                <strong
                  style={{
                    color: "var(--color-primary)",
                  }}
                >
                  Lightboxes
                </strong>{" "}
                Playlist
              </h3>
              {/*Watch out videos to find out how HueBox can enhance your brand.*/}
              <FeatureList
                items={[
                  {
                    title: "Brand placement",
                    content: `Animate static images to bring your brand to life like never before.`,
                  },
                  {
                    title: "Modular sizing",
                    content: `Any size dynamic LED lightboxes with reusability and sustainability at the core of its design`,
                  },
                ]}
              />
              {/*<div className="about-btn mt--30">*/}
              {/*  <Link className="btn-default" to="/about">*/}
              {/*    About HueBox*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </SplitContentVideo>
          </div>
        </div>
        <Separator />
        <div className="rn-section-gap">
          <div className="container">
            <SplitContentImage
              inContainer
              image={
                <div className="overlay-all-children">
                  <GatsbyImage
                    image={data.boxImage.childImageSharp.gatsbyImageData}
                  />
                  <GatsbyImage
                    className="hue-stops-animate-alt"
                    style={{ animationDuration: "4s" }}
                    image={data.boxImageOverlay.childImageSharp.gatsbyImageData}
                  />
                </div>
              }
              title="Turn on Attention"
            >
              <FeatureList
                items={[
                  {
                    title: "Visual Impact",
                    content: `HueBox is a leader in the development and use of dynamic LED
              lightboxes that deliver stunning visual impact across a wide range
              of promotional settings.`,
                  },
                  {
                    title: "Attention grabbing",
                    content: `Turn on the attention of your audience with a unique HueBox
              designed for your business.`,
                  },
                ]}
              />
              <div className="about-btn mt--30">
                <Link className="btn-default" to="/about">
                  About HueBox
                </Link>
              </div>
            </SplitContentImage>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default Index;

export const query = graphql`
  {
    boxImage: file(
      relativePath: {
        eq: "Hue_Box_Lipstick_Graphic_NICE_Man_and_Door_For_Scale_Lighter.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 613
          height: 558
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    boxImageOverlay: file(
      relativePath: { eq: "Hue_Box_Lipstick_Graphic_CUTOUT.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 613
          height: 558
          quality: 80
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`;
