import { motion, useAnimationControls } from "framer-motion";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { HeroVideo } from "./HeroVideo";
import { VideoIntro } from "./VideoIntro";

export const HomepageHero = () => {
  const boxControls = useAnimationControls();
  const introControls = useAnimationControls();
  const videoControls = useAnimationControls();
  const [videoState, setVideoState] = useState({
    ready: false,
    playing: false,
    paused: false,
    duration: null,
    position: 0,
    playCount: 0,
  });
  const videoElRef = useRef<HTMLVideoElement>();
  useEffect(() => {
    if (videoState.ready && !videoState.playing && videoElRef) {
      console.log("starting X", videoState.position);

      const start =
        videoState.playCount === 0
          ? Promise.resolve(introControls.set("start"))
          : videoControls
              .start({
                opacity: 0,
                transition: {
                  duration: 0.5,
                },
              })
              .then(() =>
                introControls.start("start", {
                  duration: 1,
                })
              )
              .then(
                () =>
                  new Promise((resolve) => {
                    setTimeout(resolve, 3000);
                  })
              );
      start.then(() => {
        introControls.start("end", {
          delay: 0.5,
          duration: 1,
        });
        videoControls
          .start({
            opacity: 1,
            transition: {
              duration: 0.5,
            },
          })
          .then(() => {
            videoElRef.current.play();
          });
      });
      // introControls.set({
      //   opacity: 1,
      //   display: "flex",
      // });
    }
  }, [videoState.playing, videoState.ready]);
  useEffect(() => {
    if (videoState.playing && !videoState.paused) {
      console.log("starting", videoState.position);
      if (videoState.position < 0.01) {
        boxControls.set({
          width: "100%",
        });
      }
      boxControls
        .start({
          width: "0%",
          transition: {
            duration: videoState.duration - videoState.position,
          },
          // transitionEnd: {
          //   opacity: 1,
          // },
        })
        .then(() => {});
    } else {
      boxControls.stop();
    }
  }, [videoState.playing, videoState.paused]);
  return (
    <div
      className="height-950-disabled bg-overlay bg_image"
      style={{
        width: "100%",
        aspectRatio: "16/9",
        // backgroundImage: `url(https://picsum.photos/id/857/640/480)`,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
        overflow: "hidden",
      }}
    >
      <HeroVideo
        videoElRef={videoElRef}
        animate={videoControls}
        onStateChange={(event, values) => {
          const extra =
            event === "playing" && !videoState.paused
              ? { playCount: videoState.playCount + 1 }
              : {};
          setVideoState({ ...videoState, ...values, ...extra });
        }}
      />
      {/*<motion.div*/}
      {/*  style={{*/}
      {/*    position: "absolute",*/}
      {/*    background: "red",*/}
      {/*    zIndex: 9999,*/}
      {/*    width: 0,*/}
      {/*    height: 40,*/}
      {/*  }}*/}
      {/*  animate={boxControls}*/}
      {/*/>*/}
      <VideoIntro animate={introControls} />
    </div>
  );
};
