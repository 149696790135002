import { AnimationControls, motion } from "framer-motion";
import { ReactComponent as LogoBox } from "../../assets/images/logo-box-no-border.svg";
import { TurnOnAttentionText } from "../TurnOnAttentionText";
import * as React from "react";

const introVariants = {
  start: { opacity: 1 },
  end: { opacity: 0 },
};
// const introHeadingVariants = {
//   start: { x: 0, scale: 1, y: 0 },
//   end: { x: "-250vw", scale: 5, y: "-25vw" },
// };
// const introSubHeadingVariants = {
//   start: { x: 0, scale: 1, y: 0 },
//   end: { x: "50vw", scale: 0.2, y: "-5vw" },
// };

const introHeadingVariants = {
  start: { y: 0 },
  end: { y: "-150vh" },
};
const introSubHeadingVariants = {
  start: { y: 0 },
  end: { y: "150vh" },
};

export function VideoIntro(props: { animate: AnimationControls }) {
  return (
    <motion.div
      style={{
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      animate={props.animate}
      variants={introVariants}
    >
      <motion.div
        variants={introHeadingVariants}
        style={{ display: "inline-block", marginBottom: 16 }}
      >
        <LogoBox
          style={{
            height: "20vw",
            // boxShadow: `0 0 60px 30px #fff,
            // 0 0 100px 60px #f0f,
            // 0 0 140px 90px #0ff`,
            boxShadow: `0 0 3vw 1vw  #feeb34`,
          }}
        />
      </motion.div>
      <motion.div variants={introSubHeadingVariants}>
        <TurnOnAttentionText style={{ width: "50vw" }} />
      </motion.div>
    </motion.div>
  );
}
