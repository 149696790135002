import { motion } from "framer-motion";
import * as React from "react";

export function TurnOnAttentionText(props) {
  return (
    <motion.div
      {...props}
      style={{
        ...(props.style ?? {}),
        // background:
        //   "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(254,235,52,1) 100%)",
        // background: "url(https://picsum.photos/1280/800?bg-image-grad.jpg)",
        backgroundPositionY: "0%",
        backgroundPositionX: "0%",
        background:
          "linear-gradient(70deg, rgba(255,255,255,1) 0%, rgba(254,235,52,1) 100%)",
        backgroundSize: "150% 150%",
        clipPath: "url(#turn-on-attention-clip-path)",
        aspectRatio: "19/2",
        filter: "blur(15px)",
      }}
      animate={{
        backgroundPositionX: "100%",
        backgroundPositionY: "100%",
        transition: {
          duration: 4,
          repeat: Infinity,
          repeatType: "reverse",
        },
      }}
    />
  );
}
