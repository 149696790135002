import * as React from "react";
import { AnimationControls, motion } from "framer-motion";
import heroVideo from "../../assets/videos/turn-on-attention-coloured-no-logo.mp4";
import { useEffect } from "react";

export function HeroVideo(props: {
  videoElRef: React.MutableRefObject<HTMLVideoElement | undefined>;
  animate: AnimationControls;
  onStateChange: (event: string, values: any) => void;
}) {
  useEffect(() => {
    if (props.videoElRef.current?.readyState === 4) {
      props.onStateChange("canPlay", {
        ready: true,
      });
    }
  }, [props.videoElRef]);
  return (
    <motion.video
      ref={props.videoElRef}
      className="videoTag"
      // autoPlay
      // controls
      // loop
      muted
      style={{
        opacity: 0,
        top: 0,
        position: "absolute",
        // zIndex: 999,
        objectFit: "contain",
        objectPosition: "center",
        backgroundColor: "#050505",
        width: "100%",
        height: "100%",
        // filter: "brightness(0.5)",
      }}
      animate={props.animate}
      onCanPlay={() => {
        props.onStateChange("canPlay", {
          ready: true,
        });
      }}
      onTimeUpdate={(event) => {
        props.onStateChange("timeUpdate", {
          duration: event.currentTarget.duration,
          position: event.currentTarget.currentTime,
        });
      }}
      onPlaying={(event) => {
        props.onStateChange("playing", {
          playing: true,
          paused: false,
          duration: event.currentTarget.duration,
          position: event.currentTarget.currentTime,
        });
      }}
      onPause={(event) => {
        props.onStateChange("pause", {
          playing: true,
          paused: true,
          duration: event.currentTarget.duration,
          position: event.currentTarget.currentTime,
        });
      }}
      onEnded={(event) => {
        props.onStateChange("ended", {
          playing: false,
          paused: false,
          duration: event.currentTarget.duration,
          position: event.currentTarget.currentTime,
        });
      }}
    >
      <source src={heroVideo} type="video/mp4" />
    </motion.video>
  );
}
