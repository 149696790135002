import React from "react";
import VideoTwo from "./video/VideoTwo";

export const SplitContentVideo = ({ video, children }) => {
  return (
    <div className="container">
      <div className="row row--40 align-items-center">
        <div className="col-lg-6">
          <div className="video-btn">
            <VideoTwo
              imagename={`${video.image}`}
              galleryItem={video}
              type={video.type}
            />
          </div>
        </div>
        <div className="col-lg-6 mt_md--40 mt_sm--40">
          <div className="content">
            <div className="inner">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
